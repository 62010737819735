@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;    

@font-face {
  font-family: 'Avenir Next LT Pro, Regular';
  src: local('Avenir Next LT Pro, Regular'), url(../public/fonts/AvenirNextLTPro-Regular.otf) format('Otf');
}

.active {
  transition: all 0.4s ease-in-out;
  color: #ff0000;
}

.Typewriter__cursor {
color: white;
}